import { edenFetch } from "@elysiajs/eden";
import type { App } from "@prms/service/types/app";

const url = import.meta.env.DEV ? "http://127.0.0.1:8080" : "//mxyhi.com/prms";

export const appFetch = edenFetch<App>(url, {
  fetcher: async (url, options = {}) => {
    // options.headers = {
    //   ...options?.headers,
    //   t: `${Date.now()}`,
    //   "request-id": `${Date.now()}`,
    // };

    // Object.assign(options, {
    //   mode: "no-cors",
    //   headers: {
    //     t: `${Date.now()}`,
    //     "request-id": `${Date.now()}`,
    //     ...options.headers,
    //   },
    // } as RequestInit);

    console.log(url, options);
    const result = await fetch(url, options);
    console.log(result);
    return result;
  },
});

// Call [GET] at '/'

// // Call [POST] at '/nendoroid/:id'
// const { data: nendoroid, error } = await app.nendoroid({ id: 1895 }).post({
//   name: "Skadi",
//   from: "Arknights",
// });
