import { Router } from "@solidjs/router";
import { appFetch } from "./apis";
import "./app.css";
import DefaultLayout from "./layouts/default";
import { routes } from "./router";

function App() {
  const myHeaders = new Headers();
  myHeaders.append("---t", "123123");
  appFetch("/api/v1/home", {
    query: {
      name: "Elysia",
    },
    // headers: myHeaders,
    headers: {
      "x-request-id": "123123",
    },
  }).then((res) => {
    console.log(res);

    console.log(JSON.stringify(res.headers));
  });
  return <Router root={DefaultLayout}>{routes}</Router>;
}

export default App;
