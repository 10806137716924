import type { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

// console.log(import.meta.glob);
// console.log(import.meta);
// const mods = import.meta.glob(`./pages/**/*.tsx`);

// console.log(mods);

export const routes: RouteDefinition[] = [
  {
    path: "/",
    component: lazy(() => import("@/pages/index.tsx")),
    children: [],
  },
];
