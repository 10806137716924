import { ColorModeProvider, ColorModeScript } from "@kobalte/core";
import { MetaProvider } from "@solidjs/meta";
import { Suspense } from "solid-js";

const DefaultLayout = (props: any) => {
  return (
    <MetaProvider>
      <Suspense>
        <ColorModeScript />
        <ColorModeProvider>{props.children}</ColorModeProvider>
      </Suspense>
    </MetaProvider>
  );
};

export default DefaultLayout;
